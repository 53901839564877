import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ScreenSizeProvider } from "./contexts/ScreenSizeContext";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const appConfig = {
  socialLinks: [
    "https://www.linkedin.com/in/jaakko-mantila/",
    "https://github.com/j4yc0b",
  ],
  socialIcons: [faLinkedin, faGithub],
  tableColumnNames: ["N", "User Name", "Profile Link"],
  effectiveDate: "29th June 2024",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ScreenSizeProvider>
    <React.StrictMode>
      <App config={appConfig} />
    </React.StrictMode>
  </ScreenSizeProvider>
);

const domNode = document.getElementById("navigation");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
