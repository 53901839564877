import { Switch } from "@mui/material";
import React, { useState, useEffect } from "react";

const DarkMode = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <div>
      <p className="flex flex-col text-xs md:flex-row md:text-sm">Dark mode</p>
      <Switch
        onChange={() => setDarkMode(!darkMode)}
        className="p-2 bg-gray-200 dark:bg-gray-800 text-black dark:text-white rounded float-right"
      ></Switch>
    </div>
  );
};

export default DarkMode;
