import { useLocation } from "react-router-dom";
import propTypes from "prop-types";
import DataTable from "./DataTable";

function AccountsTable({ columnNames }) {
  const location = useLocation();
  const accountsList = location.state.accountsList;

  return (
    <div class="accounts-table" className="m-3 my-6">
      {accountsList.length > 0 && (
        <table className="">
          <thead>
            <tr className="text-xl">
              {columnNames.map((item, _) => (
                <th>{item}</th>
              ))}
            </tr>
          </thead>
          <DataTable dataList={accountsList} />
        </table>
      )}
    </div>
  );
}

AccountsTable.propTypes = {
  columnNames: propTypes.array,
  hasError: propTypes.bool,
};

AccountsTable.defaultProps = {
  columnNames: ["N", "User Name", "Profile Link"],
  hasError: false,
};

export default AccountsTable;
