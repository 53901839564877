function DataTable({ dataList }) {
  return (
    <>
      <tbody>
        {dataList.map((account, index) => (
          <tr key={index}>
            <td>{index + 1}.</td>
            <td>{account.user_name}</td>
            <td>
              <a
                href={account.link}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                {account.link}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
}

export default DataTable;
