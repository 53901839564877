import React, { useState } from "react";
import propTypes from "prop-types";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ListGeneric from "./Components/ListGeneric";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Upload from "./Components/Upload";
import AccountsTable from "./Components/AccountsTable";
import LegalNotes from "./Components/LegalNotes";
import "./styles/tailwind.css";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { useScreenSize } from "./contexts/ScreenSizeContext";

function App({ config }) {
  const { socialLinks, socialIcons, tableColumnNames, effectiveDate } = config;
  const [hasError, setHasError] = useState(false);
  const { isDesktop, isMobile } = useScreenSize();

  return (
    <div className="dark:bg-gray-800 dark:text-white">
      <section
        className={`${isDesktop ? "mx-12" : isMobile ? "mx-6" : "mx-12"}`}
      >
        <Router>
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Upload setHasError={setHasError} />
                  <ListGeneric hasError={hasError} />
                </>
              }
            />
            <Route
              path="/accounts"
              element={<AccountsTable columnNames={tableColumnNames} />}
            />
            <Route
              path="/legal"
              element={<LegalNotes effectiveDate={effectiveDate} />}
            />
          </Routes>
          <Footer socialLinks={socialLinks} socialIcons={socialIcons} />
          <Analytics />
          <SpeedInsights />
        </Router>
      </section>
    </div>
  );
}

export default App;

App.propTypes = {
  socialLinks: propTypes.array,
  socialIcons: propTypes.array,
  tableColumnNames: propTypes.array,
  effectiveDate: propTypes.string,
};
