// ScreenSizeContext.js
import React, { createContext, useContext } from "react";
import { useMediaQuery } from "react-responsive";

const ScreenSizeContext = createContext();

export const ScreenSizeProvider = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1223 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const value = {
    isDesktop,
    isTablet,
    isMobile,
  };

  console.log("ScreenSizeContext value:", value); // Debugging line

  return (
    <ScreenSizeContext.Provider value={value}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

export const useScreenSize = () => {
  const context = useContext(ScreenSizeContext);
  if (context === undefined) {
    throw new Error("useScreenSize must be used within a ScreenSizeProvider");
  }
  return context;
};
