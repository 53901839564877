import { useNavigate } from "react-router-dom";
import DarkMode from "./DarkMode";
import { useScreenSize } from "../contexts/ScreenSizeContext";

function Header() {
  const navigate = useNavigate();
  const handleClick = () => navigate("/");
  const { isDesktop, isTablet, isMobile } = useScreenSize();

  return (
    <div className="shadow-lg dark:bg-gray-800 dark:text-white">
      <header className="">
        <div className="flex items-center justify-between">
          <a onClick={handleClick} className="">
            <h1
              className={`${
                isDesktop ? "header" : isMobile ? "header text-3xl" : "header"
              } mt-3 hover:text-blue-700 hover:underline hover:cursor-pointer`}
            >
              See who's not following you back
            </h1>
          </a>
          <div className="mt-3 mx-2 font-bold">
            <DarkMode />
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
