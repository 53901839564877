import propTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer({ socialLinks, socialIcons }) {
  const navigate = useNavigate();
  const handleClick = () => navigate("/legal");

  return (
    <div className="flex items-end justify-between dark:bg-gray-800 dark:text-white">
      {/* <div className="m-3 flex items-start dark:bg-gray-800 dark:text-white">
        {socialLinks.map((item, key) => (
          <a
            href={item}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-800 hover:text-gray-600 mx-2 dark:bg-gray-800 dark:text-white"
          >
            <FontAwesomeIcon icon={socialIcons[key]} size="2x" />
          </a>
        ))}
      </div> */}
      <div className="m-3">
        <a onClick={handleClick} className="link">
          <p className="font-bold">Legal notes</p>
        </a>
      </div>
    </div>
  );
}

export default Footer;

Footer.propTypes = {
  socialLinks: propTypes.array,
  socialIcons: propTypes.array,
};
